export default {
    // 开发环境
    // defaultBaseUrl: '//api.yuduhulian.com',
    // shdHost: 'http://192.168.3.101:888',



    // // // // // // 生产环境
    defaultBaseUrl:'/prod-api',
    shdHost:'/erp',


    ak:'shangchuanbeizhu'
}